import React from "react"
import CustomPage from "../components/customPage/CustomPage"
import Seo from "../components/seo/Seo"


export default function About() {

  return (
    <>
      <Seo title="404" pathname="404" />
      <CustomPage title="404 Sorry page not found"/>
    </>
  )
}
